namespace $ {
	export class $mol_icon_chevron_right extends $mol_icon {
		
		/**
		 * ```tree
		 * path \M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z
		 * ```
		 */
		path() {
			return "M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
		}
	}
	
}


namespace $ {
	export class $hyoo_invest_stock_row extends $mol_row {
		
		/**
		 * ```tree
		 * stock $hyoo_invest_stock / *
		 * ```
		 */
		@ $mol_mem
		stock() {
			const obj = new this.$.$hyoo_invest_stock(
				{
				}
			)
			
			return obj
		}
		
		/**
		 * ```tree
		 * sub /
		 * 	<= Info
		 * 	<= Metrics
		 * ```
		 */
		sub() {
			return [
				this.Info(),
				this.Metrics()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * title_block_label @ \Title
		 * ```
		 */
		title_block_label() {
			return this.$.$mol_locale.text( '$hyoo_invest_stock_row_title_block_label' )
		}
		
		/**
		 * ```tree
		 * tools /
		 * ```
		 */
		tools() {
			return [
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Tools $mol_view
		 * 	minimal_height 24
		 * 	sub <= tools
		 * ```
		 */
		@ $mol_mem
		Tools() {
			const obj = new this.$.$mol_view()
			
			obj.minimal_height = () => 24
			obj.sub = () => this.tools()
			
			return obj
		}
		
		/**
		 * ```tree
		 * title?val \
		 * ```
		 */
		@ $mol_mem
		title(val?: any) {
			if ( val !== undefined ) return val as never
			return ""
		}
		
		/**
		 * ```tree
		 * enabled true
		 * ```
		 */
		enabled() {
			return true
		}
		
		/**
		 * ```tree
		 * Title $mol_string
		 * 	value?val <=> title?val
		 * 	enabled <= enabled
		 * ```
		 */
		@ $mol_mem
		Title() {
			const obj = new this.$.$mol_string()
			
			obj.value = (val?: any) => this.title(val)
			obj.enabled = () => this.enabled()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Title_block $mol_labeler
		 * 	label /
		 * 		<= title_block_label
		 * 		<= Tools
		 * 	Content <= Title
		 * ```
		 */
		@ $mol_mem
		Title_block() {
			const obj = new this.$.$mol_labeler()
			
			obj.label = () => [
				this.title_block_label(),
				this.Tools()
			] as readonly any[]
			obj.Content = () => this.Title()
			
			return obj
		}
		
		/**
		 * ```tree
		 * result_mood \
		 * ```
		 */
		result_mood() {
			return ""
		}
		
		/**
		 * ```tree
		 * rate_annual?val 0
		 * ```
		 */
		@ $mol_mem
		rate_annual(val?: any) {
			if ( val !== undefined ) return val as never
			return 0
		}
		
		/**
		 * ```tree
		 * Rate $mol_number
		 * 	value?val <=> rate_annual?val
		 * 	enabled <= enabled
		 * ```
		 */
		@ $mol_mem
		Rate() {
			const obj = new this.$.$mol_number()
			
			obj.value = (val?: any) => this.rate_annual(val)
			obj.enabled = () => this.enabled()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Rate_block $hyoo_invest_labeler
		 * 	title @ \Annual rate
		 * 	mood <= result_mood
		 * 	Content <= Rate
		 * ```
		 */
		@ $mol_mem
		Rate_block() {
			const obj = new this.$.$hyoo_invest_labeler()
			
			obj.title = () => this.$.$mol_locale.text( '$hyoo_invest_stock_row_Rate_block_title' )
			obj.mood = () => this.result_mood()
			obj.Content = () => this.Rate()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Main $hyoo_invest_stock_row_group sub /
		 * 	<= Title_block
		 * 	<= Rate_block
		 * ```
		 */
		@ $mol_mem
		Main() {
			const obj = new this.$.$hyoo_invest_stock_row_group()
			
			obj.sub = () => [
				this.Title_block(),
				this.Rate_block()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * income?val 0
		 * ```
		 */
		@ $mol_mem
		income(val?: any) {
			if ( val !== undefined ) return val as never
			return 0
		}
		
		/**
		 * ```tree
		 * Income $mol_number
		 * 	value?val <=> income?val
		 * 	enabled false
		 * ```
		 */
		@ $mol_mem
		Income() {
			const obj = new this.$.$mol_number()
			
			obj.value = (val?: any) => this.income(val)
			obj.enabled = () => false
			
			return obj
		}
		
		/**
		 * ```tree
		 * Income_block $hyoo_invest_labeler
		 * 	title @ \Income
		 * 	mood <= result_mood
		 * 	Content <= Income
		 * ```
		 */
		@ $mol_mem
		Income_block() {
			const obj = new this.$.$hyoo_invest_labeler()
			
			obj.title = () => this.$.$mol_locale.text( '$hyoo_invest_stock_row_Income_block_title' )
			obj.mood = () => this.result_mood()
			obj.Content = () => this.Income()
			
			return obj
		}
		
		/**
		 * ```tree
		 * duration?val 0
		 * ```
		 */
		@ $mol_mem
		duration(val?: any) {
			if ( val !== undefined ) return val as never
			return 0
		}
		
		/**
		 * ```tree
		 * Duration $mol_number
		 * 	value?val <=> duration?val
		 * 	enabled false
		 * ```
		 */
		@ $mol_mem
		Duration() {
			const obj = new this.$.$mol_number()
			
			obj.value = (val?: any) => this.duration(val)
			obj.enabled = () => false
			
			return obj
		}
		
		/**
		 * ```tree
		 * Duration_block $mol_labeler
		 * 	title @ \Duration
		 * 	Content <= Duration
		 * ```
		 */
		@ $mol_mem
		Duration_block() {
			const obj = new this.$.$mol_labeler()
			
			obj.title = () => this.$.$mol_locale.text( '$hyoo_invest_stock_row_Duration_block_title' )
			obj.Content = () => this.Duration()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Results $hyoo_invest_stock_row_group sub /
		 * 	<= Income_block
		 * 	<= Duration_block
		 * ```
		 */
		@ $mol_mem
		Results() {
			const obj = new this.$.$hyoo_invest_stock_row_group()
			
			obj.sub = () => [
				this.Income_block(),
				this.Duration_block()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Info $hyoo_invest_stock_row_group sub /
		 * 	<= Main
		 * 	<= Results
		 * ```
		 */
		@ $mol_mem
		Info() {
			const obj = new this.$.$hyoo_invest_stock_row_group()
			
			obj.sub = () => [
				this.Main(),
				this.Results()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * enter_amount?val 0
		 * ```
		 */
		@ $mol_mem
		enter_amount(val?: any) {
			if ( val !== undefined ) return val as never
			return 0
		}
		
		/**
		 * ```tree
		 * Enter_amount $mol_number
		 * 	value?val <=> enter_amount?val
		 * 	enabled <= enabled
		 * ```
		 */
		@ $mol_mem
		Enter_amount() {
			const obj = new this.$.$mol_number()
			
			obj.value = (val?: any) => this.enter_amount(val)
			obj.enabled = () => this.enabled()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Enter_amount_block $mol_labeler
		 * 	title @ \Enter amount
		 * 	Content <= Enter_amount
		 * ```
		 */
		@ $mol_mem
		Enter_amount_block() {
			const obj = new this.$.$mol_labeler()
			
			obj.title = () => this.$.$mol_locale.text( '$hyoo_invest_stock_row_Enter_amount_block_title' )
			obj.Content = () => this.Enter_amount()
			
			return obj
		}
		
		/**
		 * ```tree
		 * enter_date?val $mol_time_moment
		 * ```
		 */
		@ $mol_mem
		enter_date(val?: any) {
			if ( val !== undefined ) return val as never
			const obj = new this.$.$mol_time_moment()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Enter_date $mol_date
		 * 	value_moment?val <=> enter_date?val
		 * 	enabled <= enabled
		 * ```
		 */
		@ $mol_mem
		Enter_date() {
			const obj = new this.$.$mol_date()
			
			obj.value_moment = (val?: any) => this.enter_date(val)
			obj.enabled = () => this.enabled()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Enter_date_block $mol_labeler
		 * 	title @ \Enter date
		 * 	Content <= Enter_date
		 * ```
		 */
		@ $mol_mem
		Enter_date_block() {
			const obj = new this.$.$mol_labeler()
			
			obj.title = () => this.$.$mol_locale.text( '$hyoo_invest_stock_row_Enter_date_block_title' )
			obj.Content = () => this.Enter_date()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Enter $hyoo_invest_stock_row_group sub /
		 * 	<= Enter_amount_block
		 * 	<= Enter_date_block
		 * ```
		 */
		@ $mol_mem
		Enter() {
			const obj = new this.$.$hyoo_invest_stock_row_group()
			
			obj.sub = () => [
				this.Enter_amount_block(),
				this.Enter_date_block()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * exit_amount?val 0
		 * ```
		 */
		@ $mol_mem
		exit_amount(val?: any) {
			if ( val !== undefined ) return val as never
			return 0
		}
		
		/**
		 * ```tree
		 * Exit_amount $mol_number
		 * 	value?val <=> exit_amount?val
		 * 	enabled <= enabled
		 * ```
		 */
		@ $mol_mem
		Exit_amount() {
			const obj = new this.$.$mol_number()
			
			obj.value = (val?: any) => this.exit_amount(val)
			obj.enabled = () => this.enabled()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Exit_amount_block $mol_labeler
		 * 	title @ \Exit amount
		 * 	Content <= Exit_amount
		 * ```
		 */
		@ $mol_mem
		Exit_amount_block() {
			const obj = new this.$.$mol_labeler()
			
			obj.title = () => this.$.$mol_locale.text( '$hyoo_invest_stock_row_Exit_amount_block_title' )
			obj.Content = () => this.Exit_amount()
			
			return obj
		}
		
		/**
		 * ```tree
		 * exit_date_mood \
		 * ```
		 */
		exit_date_mood() {
			return ""
		}
		
		/**
		 * ```tree
		 * exit_date?val $mol_time_moment
		 * ```
		 */
		@ $mol_mem
		exit_date(val?: any) {
			if ( val !== undefined ) return val as never
			const obj = new this.$.$mol_time_moment()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Exit_date $mol_date
		 * 	value_moment?val <=> exit_date?val
		 * 	enabled <= enabled
		 * ```
		 */
		@ $mol_mem
		Exit_date() {
			const obj = new this.$.$mol_date()
			
			obj.value_moment = (val?: any) => this.exit_date(val)
			obj.enabled = () => this.enabled()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Exit_date_block $hyoo_invest_labeler
		 * 	title @ \Exit date
		 * 	mood <= exit_date_mood
		 * 	Content <= Exit_date
		 * ```
		 */
		@ $mol_mem
		Exit_date_block() {
			const obj = new this.$.$hyoo_invest_labeler()
			
			obj.title = () => this.$.$mol_locale.text( '$hyoo_invest_stock_row_Exit_date_block_title' )
			obj.mood = () => this.exit_date_mood()
			obj.Content = () => this.Exit_date()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Exit $hyoo_invest_stock_row_group sub /
		 * 	<= Exit_amount_block
		 * 	<= Exit_date_block
		 * ```
		 */
		@ $mol_mem
		Exit() {
			const obj = new this.$.$hyoo_invest_stock_row_group()
			
			obj.sub = () => [
				this.Exit_amount_block(),
				this.Exit_date_block()
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * Metrics $hyoo_invest_stock_row_group sub /
		 * 	<= Enter
		 * 	<= Exit
		 * ```
		 */
		@ $mol_mem
		Metrics() {
			const obj = new this.$.$hyoo_invest_stock_row_group()
			
			obj.sub = () => [
				this.Enter(),
				this.Exit()
			] as readonly any[]
			
			return obj
		}
	}
	
	export class $hyoo_invest_stock_row_group extends $mol_view {
	}
	
}


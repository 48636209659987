namespace $ {
	export class $mol_icon_chevron_left extends $mol_icon {
		
		/**
		 * ```tree
		 * path \M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z
		 * ```
		 */
		path() {
			return "M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
		}
	}
	
}


namespace $ {
	export class $hyoo_invest_labeler extends $mol_labeler {
		
		/**
		 * ```tree
		 * attr *
		 * 	^
		 * 	hyoo_invest_labeler_mood <= mood
		 * ```
		 */
		attr() {
			return {
				...super.attr(),
				hyoo_invest_labeler_mood: this.mood()
			}
		}
		
		/**
		 * ```tree
		 * mood \
		 * ```
		 */
		mood() {
			return ""
		}
	}
	
}


namespace $ {
	export class $mol_icon_minus extends $mol_icon {
		
		/**
		 * ```tree
		 * path \M19,13H5V11H19V13Z
		 * ```
		 */
		path() {
			return "M19,13H5V11H19V13Z"
		}
	}
	
}


namespace $ {
	export class $hyoo_invest extends $mol_page {
		
		/**
		 * ```tree
		 * plugins / <= Theme
		 * ```
		 */
		plugins() {
			return [
				this.Theme()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * tools /
		 * 	<= Sources
		 * 	<= Lights
		 * ```
		 */
		tools() {
			return [
				this.Sources(),
				this.Lights()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * title @ \Private investments
		 * ```
		 */
		title() {
			return this.$.$mol_locale.text( '$hyoo_invest_title' )
		}
		
		/**
		 * ```tree
		 * body / <= Stock_list
		 * ```
		 */
		body() {
			return [
				this.Stock_list()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * store $hyoo_invest_store / * stocks /
		 * ```
		 */
		@ $mol_mem
		store() {
			const obj = new this.$.$hyoo_invest_store(
				{
					stocks: [
					] as readonly any[]
				}
			)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Stock_row* $hyoo_invest_stock_row
		 * 	stock <= stock*
		 * 	tools <= stock_row_tools*
		 * ```
		 */
		@ $mol_mem_key
		Stock_row(id: any) {
			const obj = new this.$.$hyoo_invest_stock_row()
			
			obj.stock = () => this.stock(id)
			obj.tools = () => this.stock_row_tools(id)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Stock_summary $hyoo_invest_stock_row
		 * 	enabled false
		 * 	rate_annual <= rate_annual
		 * 	stock <= stock_summary
		 * ```
		 */
		@ $mol_mem
		Stock_summary() {
			const obj = new this.$.$hyoo_invest_stock_row()
			
			obj.enabled = () => false
			obj.rate_annual = () => this.rate_annual()
			obj.stock = () => this.stock_summary()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Theme $mol_theme_auto
		 * ```
		 */
		@ $mol_mem
		Theme() {
			const obj = new this.$.$mol_theme_auto()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Sources $mol_link_source uri \https://github.com/hyoo-ru/invest.hyoo.ru
		 * ```
		 */
		@ $mol_mem
		Sources() {
			const obj = new this.$.$mol_link_source()
			
			obj.uri = () => "https://github.com/hyoo-ru/invest.hyoo.ru"
			
			return obj
		}
		
		/**
		 * ```tree
		 * Lights $mol_lights_toggle
		 * ```
		 */
		@ $mol_mem
		Lights() {
			const obj = new this.$.$mol_lights_toggle()
			
			return obj
		}
		
		/**
		 * ```tree
		 * stock_list /
		 * ```
		 */
		stock_list() {
			return [
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * stock_rows <= stock_list
		 * ```
		 */
		stock_rows() {
			return this.stock_list()
		}
		
		/**
		 * ```tree
		 * Stock_list $mol_list rows <= stock_rows
		 * ```
		 */
		@ $mol_mem
		Stock_list() {
			const obj = new this.$.$mol_list()
			
			obj.rows = () => this.stock_rows()
			
			return obj
		}
		
		/**
		 * ```tree
		 * stock* $hyoo_invest_stock / *
		 * ```
		 */
		@ $mol_mem_key
		stock(id: any) {
			const obj = new this.$.$hyoo_invest_stock(
				{
				}
			)
			
			return obj
		}
		
		/**
		 * ```tree
		 * stock_drop*?event null
		 * ```
		 */
		@ $mol_mem_key
		stock_drop(id: any, event?: any) {
			if ( event !== undefined ) return event as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Stock_drop_icon* $mol_icon_cross
		 * ```
		 */
		@ $mol_mem_key
		Stock_drop_icon(id: any) {
			const obj = new this.$.$mol_icon_cross()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Stock_drop* $mol_button_minor
		 * 	click?event <=> stock_drop*?event
		 * 	sub / <= Stock_drop_icon*
		 * ```
		 */
		@ $mol_mem_key
		Stock_drop(id: any) {
			const obj = new this.$.$mol_button_minor()
			
			obj.click = (event?: any) => this.stock_drop(id, event)
			obj.sub = () => [
				this.Stock_drop_icon(id)
			] as readonly any[]
			
			return obj
		}
		
		/**
		 * ```tree
		 * stock_row_tools* / <= Stock_drop*
		 * ```
		 */
		stock_row_tools(id: any) {
			return [
				this.Stock_drop(id)
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * rate_annual 0
		 * ```
		 */
		rate_annual() {
			return 0
		}
		
		/**
		 * ```tree
		 * stock_summary_title @ \Summary
		 * ```
		 */
		stock_summary_title() {
			return this.$.$mol_locale.text( '$hyoo_invest_stock_summary_title' )
		}
		
		/**
		 * ```tree
		 * enter_amount 0
		 * ```
		 */
		enter_amount() {
			return 0
		}
		
		/**
		 * ```tree
		 * enter_date \
		 * ```
		 */
		enter_date() {
			return ""
		}
		
		/**
		 * ```tree
		 * exit_amount 0
		 * ```
		 */
		exit_amount() {
			return 0
		}
		
		/**
		 * ```tree
		 * exit_date \
		 * ```
		 */
		exit_date() {
			return ""
		}
		
		/**
		 * ```tree
		 * stock_summary $hyoo_invest_stock / *
		 * 	title <= stock_summary_title
		 * 	enter_amount <= enter_amount
		 * 	enter_date <= enter_date
		 * 	exit_amount <= exit_amount
		 * 	exit_date <= exit_date
		 * ```
		 */
		@ $mol_mem
		stock_summary() {
			const obj = new this.$.$hyoo_invest_stock(
				{
					title: this.stock_summary_title(),
					enter_amount: this.enter_amount(),
					enter_date: this.enter_date(),
					exit_amount: this.exit_amount(),
					exit_date: this.exit_date()
				}
			)
			
			return obj
		}
	}
	
}


namespace $ {

	const { per , rem } = $mol_style_unit

	$mol_style_define( $hyoo_invest , {

		Body: {
			padding: rem(.75),
		},

		Stock_list: {
			maxWidth: rem(100),
			margin: 'auto',
		},

	} )

}
